import { Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import { useDispatch, useSelector } from "react-redux";
import { LastNewsThunk } from "../../RTK/Thunk/LastNewsThunk";
import { Link } from "react-router-dom";
import LoaderPage from "./LoaderPage";
import moment from "moment/moment";
import { MainPageNamesThunk } from "../../RTK/Thunk/MainPageNamesThunk";

const NewsPage = () => {
	const dispatch = useDispatch();
	const { sliders, reload } = useSelector((state) => state.LastNewsReducer);
	// console.log("sliders", sliders);
	const { newsTitle } = useSelector((state) => state.MainPageNamesReducer);


const getDataRef = React.useRef(true);
React.useEffect(() => {
  if (getDataRef.current) {
    getDataRef.current = false;
   	dispatch(LastNewsThunk());
    dispatch(MainPageNamesThunk());
  }
}, [getDataRef]);
	if (reload) {
		return <LoaderPage />;
	}
	return (
		<>
			<div className='box  container pt-[40px] pb-[80px] '>
				<div className='box w-full h-full  pb-[30px]'>
					<GlobalTitleBox title={newsTitle} />
				</div>

				<Grid
					container
					justifyContent='center'
					alignItems='center'
					className=' !w-full pr-[24px]'
					spacing={3}>
					{sliders?.map((el, index) => {
						return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Fade
                  direction={"up"}
                  triggerOnce
                  delay={index * 50}
                  cascade
                  damping={1}
                  duration={800}
                  className=" w-full h-full flex items-center justify-center"
                >
                  <div className="flex pb-[10px] group/item bg-color_10 justify-start text-center items-center flex-col gap-[10px]  transition-[0.3s] shadow-md hover:shadow-lg max-h-[400px] h-[400px] rounded-[9px] ">
                    <div className="img-box w-full h-full relative   rounded-[6px] overflow-hidden">
                      <img
                        src={el?.image}
                        alt=""
                        className=" w-full h-full object-cover"
                      />

                      {el?.link ? (
                        <div className=" flex group-hover/item:top-[0%]  top-[-100%]   transition-[0.3s] justify-center items-center absolute w-full  h-full bg-[#7895cb9e] left-0 ">
                          <a
                            href={el?.link}
                            target="_blank"
                            rel="noreferrer"
                            className=" cursor-pointer  transition-[0.3s] hover:bg-color_02 py-[10px] px-[25px] rounded-[6px] text-[16px] border-[1px] border-color_04 font-[600] text-color_06 "
                          >
                            إقرأ المزيد
                          </a>
                        </div>
                      ) : (
                        <div className=" flex group-hover/item:top-[0%]  top-[-100%]   transition-[0.3s] justify-center items-center absolute w-full  h-full bg-[#7895cb9e] left-0 ">
                          <Link
                            to={`/news/newsDetails/${el?.id}`}
                            className=" cursor-pointer  transition-[0.3s] hover:bg-color_02 py-[10px] px-[25px] rounded-[6px] text-[16px] border-[1px] border-color_04 font-[600] text-color_06 "
                          >
                            إقرأ المزيد
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="flex w-full max-w-[310px] justify-center items-center flex-col gap-[10px]  py-[20px] ">
                      <h5 className="text-[15px]  border-color_06 font-[500] text-color_02 ">
                        {moment(el?.created_at).format("LL")}
                      </h5>
                      <p className="text-[16px]  max-sm: !w-[80%] border-color_06 font-[500] text-color_08 line-clamp-text">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: el?.text,
                          }}
                        ></p>
                      </p>
                    </div>
                  </div>
                </Fade>
              </Grid>
            );
					})}
				</Grid>
			</div>
		</>
	);
};

export default NewsPage;
