import { MdOutlineKeyboardDoubleArrowUp } from "react-icons/md";
import { useEffect, useState } from "react";

const GoTopBox = () => {
	const [showBox, setShowBox] = useState("none");
	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY >= 600) {
				setShowBox("flex");
			} else {
				setShowBox("none");
			}
		});
	}, []);
	return (
		<>
			<div
				onClick={(e) => {
					window.scroll(0, 0);
				}}
				className={` ${
					showBox !== "none" ? "  left-[17px]" : "  left-[-80px]"
				} cursor-pointer flex  fixed bg-color_03 hover:bg-color_02   justify-center  transition-[.3s] items-center rounded-[4px] shadow-md hover:shadow-lg w-[40px] h-[45px] text-white   bottom-[40px] z-[1000] `}>
				<MdOutlineKeyboardDoubleArrowUp className=' !text-[28px] animate-bouncing_up ' />
			</div>
		</>
	);
};

export default GoTopBox;
