import { createSlice } from "@reduxjs/toolkit";

import { SectionsOfPagesThunk } from "../Thunk/SectionsOfPagesThunk";

let initState = {
	reload: true,
	SectionsOfPagesArr: [],

	error: null,
};

const SectionsOfPagesReducer = createSlice({
	name: "SectionsOfPages",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(SectionsOfPagesThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(SectionsOfPagesThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.SectionsOfPagesArr = action.payload.data;
			});
	},
});
export default SectionsOfPagesReducer.reducer;
