import { Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import { useDispatch, useSelector } from "react-redux";
import { PartnersThunk } from "../../RTK/Thunk/PartnersThunk";
import LoaderPage from "./LoaderPage";
import { MainPageNamesThunk } from "../../RTK/Thunk/MainPageNamesThunk";

const PartnersPage = () => {
	const dispatch = useDispatch();
	const { sliders, reload } = useSelector((state) => state.PartnersReducer);
	const { partnersTitle } = useSelector((state) => state.MainPageNamesReducer);

	React.useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch(MainPageNamesThunk());
			dispatch(PartnersThunk());
		}, 0);
		return () => {
			clearTimeout(debounce);
		};
	}, []);

	if (reload) {
		return <LoaderPage />;
	}
	return (
		<>
			<div className='box  container pt-[40px] pb-[80px] '>
				<div className='box w-full h-full  pb-[30px]'>
					<GlobalTitleBox title={partnersTitle} />
				</div>

				<Grid
					container
					justifyContent='center'
					alignItems='center'
					className=' !w-full pr-[24px]'
					spacing={3}>
					{sliders?.map((el, index) => {
						return (
							<Grid key={index} item xs={6} sm={6} md={3} lg={3} xl={2}>
								<Fade
									direction={"up"}
									triggerOnce
									delay={index * 50}
									cascade
									damping={1}
									duration={800}
									className=' w-full h-full flex items-center justify-center'>
									<div className='    flex-col gap-[10px] flex justify-center items-center  w-full  '>
										<div className='img-box w-full h-[145px] relative   rounded-full overflow-hidden'>
											<img
												src={el.image}
												alt=''
												className=' w-full h-full object-contain'
											/>
										</div>
										<h5 className='text-[16px] font-[600] text-color_02 line-clamp-text'>
											{el.sub_title}
										</h5>
									</div>
								</Fade>
							</Grid>
						);
					})}
				</Grid>
			</div>
		</>
	);
};

export default PartnersPage;
