import { createSlice } from "@reduxjs/toolkit";
import { MainPageSectionsStatusThunk } from "../Thunk/MainPageSectionsStatusThunk";
import { act } from "react-dom/test-utils";

let initState = {
	reload: true,
	main_sections: false,
	electronic_services: false,
	results: false,
	news: false,
	images: false,
	videos: false,
	testimonials: false,
	partners: false,
	who_are_we: false,

	error: null,
};

const MainPageSectionsStatusReducer = createSlice({
	name: "MainPageSectionsStatus",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(MainPageSectionsStatusThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(MainPageSectionsStatusThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.main_sections = action.payload.data.main_sections;
				state.electronic_services = action.payload.data.electronic_services;
				state.results = action.payload.data.results;
				state.news = action.payload.data.news;
				state.images = action.payload.data.images;
				state.videos = action.payload.data.videos;
				state.testimonials = action.payload.data.testimonials;
				state.partners = action.payload.data.partners;
				state.who_are_we = action.payload.data.who_are_we;
			});
	},
});
export default MainPageSectionsStatusReducer.reducer;
