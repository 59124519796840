import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SiteLogoThunk = createAsyncThunk(
	"SiteLogoThunk",
	async (_, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.get("https://api.noor.sa/api/public/site_logo");

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
