import React from "react";
import { Bounce } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

const GlobalButtonBox = ({ dataBt }) => {
	let navigate = useNavigate();
	return (
		<>
			<div className=' min-h-[60px] mt-[20px] w-full flex justify-center items-center gap-[20px]'>
				{dataBt.length &&
					dataBt.map((el, index) => {
						return (
							<Bounce triggerOnce delay={index * 100}>
								<button
									key={index}
									onClick={() => {
										navigate(el?.link);
									}}
									className=' global_shadow rounded-[6px] transition-[0.3s] hover:bg-color_01 hover:rounded-none  font-[500] hover:text-color_06 text-color_06 !text-[18px] text-center py-[9px] px-[30px] bg-color_03 '>
									{el?.title}
								</button>
							</Bounce>
						);
					})}
			</div>
		</>
	);
};

export default GlobalButtonBox;
