import React from "react";
import GlobalButtonBox from "../GlobalButtonBox/GlobalButtonBox";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import PhotoAlbumSliderBox from "../PhotoAlbumSliderBox/PhotoAlbumSliderBox";
import { useDispatch, useSelector } from "react-redux";
import { PhotoAlbumThunk } from "../../RTK/Thunk/PhotoAlbumThunk";

const PhotoAlbumBox = ({ photoAlbumsTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.PhotoAlbumReducer);


const getDataRef = React.useRef(true);
React.useEffect(() => {
  if (getDataRef.current) {
    getDataRef.current = false;
   		dispatch(PhotoAlbumThunk());

  }
}, [getDataRef]);
	return (
		<>
			<div className='flex justify-start py-[60px] items-center flex-col  w-full min-h-[400px] bg-color_10'>
				<GlobalTitleBox title={photoAlbumsTitle} />
				<div className='container'>
					<PhotoAlbumSliderBox sliders={sliders} />

					<GlobalButtonBox
						dataBt={[{ title: ` عرض كافة الألبومات`, link: "/photo-albums" }]}
					/>
				</div>
			</div>
		</>
	);
};

export default PhotoAlbumBox;
