import { Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import GlobalButtonBox from "../GlobalButtonBox/GlobalButtonBox";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import { useDispatch, useSelector } from "react-redux";
import { OurAchievementsThunk } from "../../RTK/Thunk/OurAchievementsThunk";

const OurAchievementsBox = ({ achievementsTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.OurAchievementsReducer);

	React.useEffect(() => {
		dispatch(OurAchievementsThunk());
	}, []);
	return (
		<>
			<div className='flex justify-start py-[40px] items-center flex-col  w-full min-h-[400px] bg-color_02'>
				<GlobalTitleBox title={achievementsTitle} TitleColor={"#fff"} />
				<div className='container'>
					<Grid
						container
						justifyContent='center'
						alignItems='center'
						className=' !w-full pr-[16px]'
						spacing={2}>
						{sliders?.map((el, index) => {
							return (
								<Grid key={index} item xs={12} sm={4} md={3} lg={3} xl={2}>
									<Fade
										cascade
										damping={1}
										triggerOnce
										direction={"up"}
										delay={index * 10}
										duration={800}
										className=' w-full h-full flex items-center justify-center'>
										<div className=' transition-[0.3s] global_shadow rounded-[6px] w-full bg-color_05 group  text-center   py-[15px] min-h-[191px]  flex justify-center flex-col items-center gap-[2px]'>
											<span className='w-12'>
												<img src={el?.image} alt='' />
											</span>
											<h4 className='font-bold text-color_01 !text-[18px]'>
												{el.title}
											</h4>
											<h2
												className=' font-[800] text-color_02 !text-[32px] -mb-3'
												dangerouslySetInnerHTML={{ __html: el.text }}
											/>

											<h4 className='font-medium text-color_02 !text-[18px]'>
												{el.sub_title}
											</h4>
										</div>
									</Fade>
								</Grid>
							);
						})}
					</Grid>
					<GlobalButtonBox
						dataBt={[
							{ title: `نبذة عنا`, link: "/about" },
							{ title: `إتصل بنا`, link: "/contact-us" },
						]}
					/>
				</div>
			</div>
		</>
	);
};

export default OurAchievementsBox;
