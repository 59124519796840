import React from "react";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import ServicesSlider from "../ServicesSlider/ServicesSlider";
import { useDispatch, useSelector } from "react-redux";
import { ElectronicServicesThunk } from "../../RTK/Thunk/ElectronicServicesThunk";

const ServicesBox = ({ electronicServicesTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.ElectronicServicesReducer);

	React.useEffect(() => {
		dispatch(ElectronicServicesThunk());
	}, []);

	return (
		<>
			<div className=' global_Padding container'>
				<GlobalTitleBox title={electronicServicesTitle} />
				<ServicesSlider sliders={sliders} />
			</div>
		</>
	);
};

export default ServicesBox;
