import { configureStore } from "@reduxjs/toolkit";

import MainPageSectionsStatusReducer from "./Reducers/MainPageSectionsStatusReducer";
import ElectronicServicesReducer from "./Reducers/ElectronicServicesReducer";
import SectionsOfPagesReducer from "./Reducers/SectionsOfPagesReducer";
import OurAchievementsReducer from "./Reducers/OurAchievementsReducer";
import AboutUsSectionReducer from "./Reducers/AboutUsSectionReducer";
import MainPageNamesReducer from "./Reducers/MainPageNamesReducer";
import ContactUsFormReducer from "./Reducers/ContactUsFormReducer";
import TestimonialsReducer from "./Reducers/TestimonialsReducer";
import ContactInfoReducer from "./Reducers/ContactInfoReducer";
import SocialLinksReducer from "./Reducers/SocialLinksReducer";
import MainSectionReducer from "./Reducers/MainSectionReducer";
import PhotoAlbumReducer from "./Reducers/PhotoAlbumReducer";
import VideoAlbumReducer from "./Reducers/VideoAlbumReducer";
import LastNewsReducer from "./Reducers/LastNewsReducer";
import SiteLogoReducer from "./Reducers/SiteLogoReducer";
import PartnersReducer from "./Reducers/PartnersReducer";
import PagesReducer from "./Reducers/PagesReducer";

export const Store = configureStore({
	reducer: {
		MainPageSectionsStatusReducer,
		ElectronicServicesReducer,
		SectionsOfPagesReducer,
		OurAchievementsReducer,
		AboutUsSectionReducer,
		MainPageNamesReducer,
		ContactUsFormReducer,
		TestimonialsReducer,
		SocialLinksReducer,
		MainSectionReducer,
		ContactInfoReducer,
		PhotoAlbumReducer,
		VideoAlbumReducer,
		PartnersReducer,
		LastNewsReducer,
		SiteLogoReducer,
		PagesReducer,
	},
});
