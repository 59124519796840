import { createSlice } from "@reduxjs/toolkit";

import { VideoAlbumThunk } from "../Thunk/VideoAlbumThunk";

let initState = {
	reload: true,
	sliders: [],
	error: null,
};

const VideoAlbumReducer = createSlice({
	name: "VideoAlbumReducer",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(VideoAlbumThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(VideoAlbumThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.sliders = action.payload.data;
			});
	},
});
export default VideoAlbumReducer.reducer;
