import { Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import { useDispatch, useSelector } from "react-redux";
import { PhotoAlbumThunk } from "../../RTK/Thunk/PhotoAlbumThunk";
import LoaderPage from "./LoaderPage";
import moment from "moment/moment";
import { MainPageNamesThunk } from "../../RTK/Thunk/MainPageNamesThunk";

const PhotoAlbumsPage = () => {
	const dispatch = useDispatch();
	const { sliders, reload } = useSelector((state) => state.PhotoAlbumReducer);
	const { photoAlbumsTitle } = useSelector(
		(state) => state.MainPageNamesReducer
	);

	React.useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch(PhotoAlbumThunk());
			dispatch(MainPageNamesThunk());
		}, 0);
		return () => {
			clearTimeout(debounce);
		};
	}, []);

	if (reload) {
		return <LoaderPage />;
	}

	return (
		<>
			<div className='box  container pt-[40px] pb-[80px] '>
				<div className='box w-full h-full  pb-[30px]'>
					<GlobalTitleBox title={photoAlbumsTitle} />
				</div>
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					className=' !w-full pr-[24px]'
					spacing={3}>
					{sliders?.map((el, index) => {
						return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Fade
                  direction={"up"}
                  triggerOnce
                  delay={index * 50}
                  cascade
                  damping={1}
                  duration={800}
                  className=" w-full h-full flex items-center justify-center"
                >
                  <div className="flex cursor-pointer  p-[20px]  bg-color_10    justify-start text-center items-center flex-col gap-[10px] transition-[0.3s]  max-w-[350px] h-[350px] max-h-[380px] min-h-[350px]rounded-md photo_album_slider_bg_animation shadow-md hover:shadow-lg">
                    <div className="img-box w-full h-full relative  z-10  rounded-[6px] overflow-hidden">
                      <img
                        src={el?.image}
                        alt=""
                        className=" w-full h-full object-cover "
                      />
                    </div>
                    <div className="flex w-full max-w-[310px] justify-center items-center flex-col gap-[8px] z-10">
                      <h5 className="text-[15px]  border-color_06 font-[500] text-color_01 ">
                        {moment(el?.created_at).format("LL")}
                      </h5>
                      <h4 className="text-[16px]  border-color_06 font-[500] text-color_01 line-clamp-text ">
                        <p dangerouslySetInnerHTML={{ __html: el.text }}></p>
                      </h4>
                    </div>
                  </div>
                </Fade>
              </Grid>
            );
					})}
				</Grid>
			</div>
		</>
	);
};

export default PhotoAlbumsPage;
