import { createSlice } from "@reduxjs/toolkit";

import { MainSectionThunk } from "../Thunk/MainSectionThunk";

let initState = {
	reload: true,
	heroSliders: [],

	error: null,
};

const MainSectionReducer = createSlice({
	name: "MainSection",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(MainSectionThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(MainSectionThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.heroSliders = action.payload.data;
			});
	},
});
export default MainSectionReducer.reducer;
