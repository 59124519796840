import React from "react";
import GlobalButtonBox from "../GlobalButtonBox/GlobalButtonBox";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import NewsSlider from "../NewsSliderBox/NewsSlider";
import { useDispatch, useSelector } from "react-redux";
import { LastNewsThunk } from "../../RTK/Thunk/LastNewsThunk";

const NewSBox = ({ newsTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.LastNewsReducer);

	const getDataRef = React.useRef(true);
	React.useEffect(() => {
		if (getDataRef.current) {
			getDataRef.current = false;
		}
	}, [getDataRef]);
	return (
		<>
			<div className=' global_Padding container '>
				<GlobalTitleBox title={newsTitle} />

				<NewsSlider sliders={sliders} />

				<GlobalButtonBox
					dataBt={[{ title: `عرض كافة الأخبار`, link: "/news" }]}
				/>
			</div>
		</>
	);
};

export default NewSBox;
