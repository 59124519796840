import React from "react";
import GlobalButtonBox from "../GlobalButtonBox/GlobalButtonBox";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import TestimonialSlider from "../TestimonialSlider/TestimonialSlider";
import { useDispatch, useSelector } from "react-redux";
import { TestimonialsThunk } from "../../RTK/Thunk/TestimonialsThunk";

const TestimonialBox = ({ testimonialsTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.TestimonialsReducer);

	React.useEffect(() => {
		dispatch(TestimonialsThunk());
	}, []);

	return (
		<div>
			<>
				<div className='flex justify-start py-[60px] items-center flex-col  w-full min-h-[400px] bg-color_02 '>
					<GlobalTitleBox title={testimonialsTitle} TitleColor={"#f6f6f6"} />
					<div className='container relative '>
						<TestimonialSlider sliders={sliders} />
						<GlobalButtonBox
							dataBt={[{ title: `عرض الجميع `, link: "/testimonial-page" }]}
						/>
					</div>
				</div>
			</>
		</div>
	);
};

export default TestimonialBox;
