import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./NewsSlider.css";

// import required modules
import { Fade } from "react-awesome-reveal";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import moment from "moment/moment";

const NewsSlider = ({ sliders }) => {
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={26}
				dir='rtl'
				loop={true}
				pagination={{
					dynamicBullets: true,
				}}
				breakpoints={{
					640: {
						slidesPerView: 1,
					},
					768: {
						slidesPerView: 2,
					},
					1024: {
						slidesPerView: 3,
					},
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className='NewsSlider !p-[15px]'>
				{sliders?.map((el, index) => {
					return (
            <SwiperSlide className=" pb-[50px] flex justify-center bg-white  items-center ">
              <Fade
                direction={"left"}
                triggerOnce
                delay={index * 10}
                cascade
                damping={1}
                duration={800}
                className=" w-full h-full flex items-center justify-center"
              >
                <div className="flex pb-[10px] group/item bg-color_10 justify-start text-center items-center flex-col gap-[10px]  transition-[0.3s] shadow-md hover:shadow-lg max-h-[400px] h-[400px] min-h-[400px] rounded-[9px] ">
                  <div className="img-box w-full h-full relative   rounded-[6px] overflow-hidden">
                    <img
                      src={el?.image}
                      alt=""
                      className=" w-full h-full object-cover"
                    />
                    {el?.link ? (
                      <div className=" flex group-hover/item:top-[0%]  top-[-100%]   transition-[0.3s] justify-center items-center absolute w-full  h-full bg-[#7895cb9e] left-0 ">
                        <a
                          href={el?.link}
                          target="_blank"
                          rel="noreferrer"
                          className=" cursor-pointer  transition-[0.3s] hover:bg-color_02 py-[10px] px-[25px] rounded-[6px] text-[16px] border-[1px] border-color_04 font-[600] text-color_06 "
                        >
                          إقرأ المزيد
                        </a>
                      </div>
                    ) : (
                      <div className=" flex group-hover/item:top-[0%]  top-[-100%]   transition-[0.3s] justify-center items-center absolute w-full  h-full bg-[#7895cb9e] left-0 ">
                        <Link
                          to={`/news/newsDetails/${el?.id}`}
                          className=" cursor-pointer  transition-[0.3s] hover:bg-color_02 py-[10px] px-[25px] rounded-[6px] text-[16px] border-[1px] border-color_04 font-[600] text-color_06 "
                        >
                          إقرأ المزيد
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="flex w-full max-w-[310px] justify-center items-center flex-col gap-[10px]  py-[20px]  min-h-[102px]">
                    <h5 className="text-[15px]  border-color_06 font-[500] text-color_02 ">
                      {moment(el?.created_at).format("LL")}
                    </h5>
                    <p className="text-[16px]  max-sm: !w-[80%] border-color_06 font-[500] text-color_08 line-clamp-text">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: el?.text,
                        }}
                      ></p>
                    </p>
                  </div>
                </div>
              </Fade>
            </SwiperSlide>
          );
				})}
			</Swiper>
		</>
	);
};

export default NewsSlider;
