import { createSlice } from "@reduxjs/toolkit";
import { LastNewsThunk } from "../Thunk/LastNewsThunk";

let initState = {
	reload: true,
	sliders: [],
	error: null,
};

const LastNewsReducer = createSlice({
	name: "LastNewsReducer",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(LastNewsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(LastNewsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.sliders = action.payload.data;
			});
	},
});
export default LastNewsReducer.reducer;
