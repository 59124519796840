import React from "react";
import { Fade } from "react-awesome-reveal";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";

const AboutPage = () => {
	return (
		<>
			<div className='box  container pt-[40px] pb-[80px] '>
				<div className='box w-full h-full  pb-[30px]'>
					<GlobalTitleBox title={"نبذة عنا"} />
				</div>
				<div className='flex flex-col justify-start items-start gap-[30px]'>
					<Fade
						direction={"up"}
						triggerOnce
						delay={10}
						cascade
						damping={1}
						duration={400}
						className='flex flex-col justify-start items-start gap-[30px]'>
						<div className='flex flex-col justify-start items-start gap-[12px]'>
							<h5 className='text-color_08 font-[600] text-[19px]'>نبذة عنا</h5>
							<p className='text-color_08 font-[500] text-[17px]'>
								هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
								هذا النص من مولد النصوص في أدوات منصة نفذلي، حيث يمكنك أن تولد
								مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد
								الحروف التى تولدها الأداة، أيضاً ستجد العديد من الأدوات الاخرى
								التي ستساعدك في حياتك اليومية . إذا كنت تحتاج إلى عدد أكبر من
								الفقرات يمكنك ذلك عبر مولد النصوص الموجود في أدوات نفذلي كما
								يمكنك العثور على العديد من الأدوات الأخرى التي تساعدك على تنفيذ
								العديد من المهام ببساطة وسهولة، هذا النص يمكن أن يتم تركيبه على
								أي تصميم دون مشكلة فلن يبدو وكأنه نص منسوخ، غير منظم، غير منسق،
								أو حتى غير مفهوم. لأنه مازال نصاً بديلاً ومؤقتاً.
							</p>
						</div>
						<div className='flex flex-col justify-start items-start gap-[12px]'>
							<h5 className='text-color_08 font-[600] text-[19px]'>رؤيتنا</h5>
							<p className='text-color_08 font-[500] text-[17px]'>
								هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
								هذا النص من مولد النصوص
							</p>
						</div>
						<div className='flex flex-col justify-start items-start gap-[12px]'>
							<h5 className='text-color_08 font-[600] text-[19px]'>رسالتنا</h5>
							<p className='text-color_08 font-[500] text-[17px]'>
								هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
								هذا النص من مولد النصوص
							</p>
						</div>
						<div className='flex flex-col justify-start items-start gap-[12px]'>
							<h5 className='text-color_08 font-[600] text-[19px]'>قيمنا</h5>
							<p className='text-color_08 font-[500] text-[17px]'>
								.هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								.هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
								هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
								هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
						</div>
						<div className='flex flex-col justify-start items-start gap-[12px]'>
							<h5 className='text-color_08 font-[600] text-[19px]'>أهدافنا</h5>
							<p className='text-color_08 font-[500] text-[17px]'>
								1- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								2- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								3- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								4- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								5- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								6- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								7- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								8- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								9- هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
						</div>
						<div className='flex flex-col justify-start items-start gap-[5px]'>
							<h5 className='text-color_08 font-[600] text-[19px]'>
								أهدافنا الاستراتيجية
							</h5>
							<p className='text-color_08 font-[500] text-[17px]'>
								1. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								2. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								3. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص.
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								4. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								5. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
							<p className='text-color_08 font-[500] text-[17px]'>
								6. هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم
								توليد هذا النص من مولد النصوص
							</p>
						</div>
					</Fade>
				</div>
			</div>
		</>
	);
};

export default AboutPage;
