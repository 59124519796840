import React from "react";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import { Link, useParams } from "react-router-dom";
import LoaderPage from "./LoaderPage";
import { useDispatch, useSelector } from "react-redux";
import { PagesThunk } from "../../RTK/Thunk/PagesThunk";

import { FaHome, FaRegFilePdf } from "react-icons/fa";
import { CgSoftwareDownload } from "react-icons/cg";
import { TbEyeShare } from "react-icons/tb";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// import required modules
import { Autoplay, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "../../Components/HeroSliderBox/HeroSliderBox.css";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { SectionsOfPagesThunk } from "../../RTK/Thunk/SectionsOfPagesThunk";

const PagesCreator = () => {
  	const { id, title } = useParams();
	const dispatch = useDispatch();

	const { pagesData, reload } = useSelector((state) => state.PagesReducer);
	const { SectionsOfPagesArr } = useSelector(
		(state) => state.SectionsOfPagesReducer
	);

	// React.useEffect(() => {
	// 	dispatch(PagesThunk());
	// }, []);
const getDataRef = React.useRef(true);
React.useEffect(() => {
  if (getDataRef.current) {
    getDataRef.current = false;
   		// dispatch(PagesThunk());
                  dispatch(SectionsOfPagesThunk(id));

  }
}, [getDataRef]);
	const currentPage = pagesData?.filter((page) => page?.id === Number(id));

	// console.log(SectionsOfPagesArr);

	if (reload) {
		return <LoaderPage />;
	}
	return (
    <>
      <div className="box  container pt-[10px] pb-[40px] ">
        <div role="presentation" className="mb-12">
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="inherit"
              to="/"
              className="flex justify-center items-center gap-1"
            >
              <FaHome />
              الرئيسية
            </Link>
            <Typography
              color="text.primary"
              className="font-medium text-[#cb970d]"
            >
              {title}
            </Typography>
            {/*<Link
							underline='hover'
							color='inherit'
							href='/material-ui/getting-started/installation/'>
							Core
						</Link>*/}
          </Breadcrumbs>
        </div>

        {SectionsOfPagesArr?.map((section) => (
          <section key={section?.id}>
            <>
              {section?.type === "slider" &&section?.status&&  (
                <section className="mb-8 ">
                  {section?.title && (
                    <Fade
                      triggerOnce
                      delay={100}
                      className=" w-full h-full flex items-center justify-center"
                    >
                      <div className="div relative w-full py-[20px] flex justify-center flex-col items-center gap-[3px] ">
                        <h1
                          className={`text-[28px] font-medium text-[#cb970d]`}
                        >
                          {section?.title}
                        </h1>
                        <div className="relative">
                          <div className=" relative  z-20 w-[18px] h-[18px]  bg-color_03 rounded-full"></div>
                          <div className=" absolute z-10  w-[120px] h-[3px]  left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-color_10 rounded-full"></div>
                        </div>
                      </div>
                    </Fade>
                  )}
                  <Swiper
                    centeredSlides={true}
                    pagination={{
                      dynamicBullets: true,
                    }}
                    loop={true}
                    autoplay={{
                      delay: 4500,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="HeroSliderBox"
                  >
                    {section?.details?.map((slider) =>
                      slider?.link ? (
                        <SwiperSlide
                          key={slider?.id}
                          className=" cursor-pointer"
                          onClick={() => window.open(slider?.link, "_blank")}
                        >
                          <img src={slider?.image} alt="" />
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide key={slider?.id}>
                          <img src={slider?.image} alt="" />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </section>
              )}
              {section?.type === "image_video" &&section?.status&&  (
                <section className="mb-8">
                  <Fade
                    triggerOnce
                    delay={100}
                    className=" w-full h-full flex items-center justify-center"
                  >
                    <div className="div relative w-full py-[20px] flex justify-center flex-col items-center gap-[3px] ">
                      <h1 className={`text-[28px] font-medium text-[#cb970d]`}>
                        {section?.title}
                      </h1>
                      <div className="relative">
                        <div className=" relative  z-20 w-[18px] h-[18px]  bg-color_03 rounded-full"></div>
                        <div className=" absolute z-10  w-[120px] h-[3px]  left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-color_10 rounded-full"></div>
                      </div>
                    </div>
                  </Fade>

                  <div className=" flex justify-center items-center ">
                    {section?.details?.url ? (
                      section?.details?.url?.includes(
                        ".mp4" || ".avi" || ".mov" || ".mkv"
                      ) ? (
                        <section className=" w-full">
                          <video src={section?.details?.url} controls />
                        </section>
                      ) : (
                        <section className=" w-full">
                          <img src={section?.details?.url} alt="" />
                        </section>
                      )
                    ) : (
                      <iframe
                        className=" w-full"
                        height="515"
                        src={`https://www.youtube.com/embed/${
                          section?.details?.link?.split("v=")[1]
                        }`}
                        controls
                        frameborder="0"
                        allowfullscreen
                        title="YouTube video player"
                      />
                    )}
                    {/* {section?.details?.link && } */}
                  </div>
                </section>
              )}
              {section?.type === "image_video_title" &&section?.status&&  (
                <section className="mb-8">
                  <Fade
                    triggerOnce
                    delay={100}
                    className=" w-full h-full flex items-center justify-center"
                  >
                    <div className="div relative w-full py-[20px] flex justify-center flex-col items-center gap-[3px] ">
                      <h1 className={`text-[28px] font-medium text-[#cb970d]`}>
                        {section?.title}
                      </h1>
                      <div className="relative">
                        <div className=" relative  z-20 w-[18px] h-[18px]  bg-color_03 rounded-full"></div>
                        <div className=" absolute z-10  w-[120px] h-[3px]  left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-color_10 rounded-full"></div>
                      </div>
                    </div>
                  </Fade>
                  <section className=" global_Padding container ">
                    <section className="flex flex-col-reverse md:flex-row justify-start items-start gap-5">
                      <div
                        className="w-full md:w-1/2 "
                        dangerouslySetInnerHTML={{
                          __html: section?.details?.text,
                        }}
                      />

                      <div className="w-full md:w-1/2 flex justify-center items-center rounded-lg relative about-us-img-box">
                        <img
                          className="rounded-lg"
                          src={section?.details?.file}
                          alt=""
                        />
                      </div>
                    </section>
                  </section>
                </section>
              )}
              {section?.type === "files" &&section?.status&&  (
                <section className="mb-8">
                  <Fade
                    triggerOnce
                    delay={100}
                    className=" w-full h-full flex items-center justify-center"
                  >
                    <div className="div relative w-full py-[20px] flex justify-center flex-col items-center gap-[3px] ">
                      <h1 className={`text-[28px] font-medium text-[#cb970d]`}>
                        {section?.title}
                      </h1>
                      <div className="relative">
                        <div className=" relative  z-20 w-[18px] h-[18px]  bg-color_03 rounded-full"></div>
                        <div className=" absolute z-10  w-[120px] h-[3px]  left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-color_10 rounded-full"></div>
                      </div>
                    </div>
                  </Fade>
                  <div className="container">
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      className=" !w-full pr-[16px]"
                      spacing={2}
                    >
                      {section?.details?.map((el, index) => {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={12}
                            sm={4}
                            md={3}
                            lg={3}
                            xl={2}
                          >
                            <Fade
                              cascade
                              damping={1}
                              triggerOnce
                              direction={"up"}
                              delay={index * 10}
                              duration={800}
                              className=" w-full h-full flex items-center justify-center "
                            >
                              <div className=" transition-[0.3s] global_shadow rounded-[6px] w-full bg-color_05 group  text-center   py-[15px] min-h-[191px]  flex justify-center flex-col items-center gap-[2px]">
                                <FaRegFilePdf className="text-[2.5rem] text-[#cb970d] mb-2" />
                                <h4 className="font-medium text-color_01 text-[18px] h-full file_name pb-12">
                                  {el.name}
                                </h4>
                                <div className="flex justify-center items-center gap-4 pt-2">
                                  <a
                                    href={el?.file}
                                    download={el?.file}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <CgSoftwareDownload
                                      className="text-2xl cursor-pointer text-[#cb970d] hover:text-[#119184]"
                                      title="تحميل الملف"
                                    />
                                  </a>
                                  <TbEyeShare
                                    onClick={() => {
                                      window.open(el?.file, "_blank");
                                    }}
                                    className="text-2xl cursor-pointer text-[#cb970d] hover:text-[#119184]"
                                    title="معاينة الملف"
                                  />
                                </div>
                              </div>
                            </Fade>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </div>
                </section>
              )}
              {section?.type === "text" &&section?.status&&  (
                <section className="mb-4">
                  <Fade
                    triggerOnce
                    delay={100}
                    className=" w-full h-full flex items-center justify-center"
                  >
                    <div className="div relative w-full py-[20px] flex justify-center flex-col items-center gap-[3px] ">
                      <h1 className={`text-[28px] font-medium text-[#cb970d]`}>
                        {section?.title}
                      </h1>
                      <div className="relative">
                        <div className=" relative  z-20 w-[18px] h-[18px]  bg-color_03 rounded-full"></div>
                        <div className=" absolute z-10  w-[120px] h-[3px]  left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-color_10 rounded-full"></div>
                      </div>
                    </div>
                  </Fade>

                  <div
                    className="w-full md:w-1/2 "
                    dangerouslySetInnerHTML={{
                      __html: section?.details?.text,
                    }}
                  />
                </section>
              )}
            </>
          </section>
        ))}
      </div>
    </>
  );
};

export default PagesCreator;
