import { createSlice } from "@reduxjs/toolkit";

import { ContactUsFormThunk } from "../Thunk/ContactUsFormThunk";

let initState = {
	reload: false,
	error: null,
};

const ContactUsFormReducer = createSlice({
	name: "ContactUsForm",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(ContactUsFormThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ContactUsFormThunk.fulfilled, (state, action) => {
				state.reload = false;
			});
	},
});
export default ContactUsFormReducer.reducer;
