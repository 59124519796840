import React from "react";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LastNewsThunk } from "../../RTK/Thunk/LastNewsThunk";
import LoaderPage from "./LoaderPage";

const NewsDetailsPage = ({ details }) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const { sliders, reload } = useSelector((state) => state.LastNewsReducer);
	React.useEffect(() => {
		dispatch(LastNewsThunk());
	}, []);
	const currentPage = sliders?.filter((page) => page?.id === Number(id));

	if (reload) {
		return <LoaderPage />;
	}
	return (
		<div className='box  container pt-[40px] pb-[80px] '>
			<div className='box w-full h-full  pb-[30px]'>
				<GlobalTitleBox title={currentPage[0]?.title} />
			</div>

			<div>
				<div className='w-full '>
					<img
						className=' my-1 mx-auto rounded-md'
						src={currentPage[0]?.image}
						alt=''
					/>
				</div>
				<div className='flex w-full max-w-[310px] justify-center items-center flex-col gap-[10px]  py-[20px]'>
					<p dangerouslySetInnerHTML={{ __html: currentPage[0]?.text }}></p>
				</div>
			</div>
		</div>
	);
};

export default NewsDetailsPage;
