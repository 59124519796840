import React from "react";
import { Fade } from "react-awesome-reveal";

const GlobalTitleBox = ({ title, TitleColor }) => {
	return (
		<>
			<Fade
				triggerOnce
				delay={100}
				className=' w-full h-full flex items-center justify-center'>
				<div className='div relative w-full  py-[30px] flex justify-center flex-col items-center gap-[3px] '>
					<h1
						style={{
							color: TitleColor || "#cb970d",
						}}
						className={`text-[34px] font-[600] `}>
						{title}
					</h1>
					<div className='relative'>
						<div className=' relative  z-20 w-[18px] h-[18px]  bg-color_03 rounded-full'></div>
						<div className=' absolute z-10  w-[120px] h-[3px]  left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%] bg-color_10 rounded-full'></div>
					</div>
				</div>
			</Fade>
		</>
	);
};

export default GlobalTitleBox;
