import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./ServicesSlider.css";

import { Fade } from "react-awesome-reveal";
import { Autoplay, Navigation, Pagination } from "swiper/modules";

const ServicesSlider = ({ sliders }) => {
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				dir='rtl'
				loop={true}
				pagination={{
					dynamicBullets: true,
				}}
				breakpoints={{
					640: {
						slidesPerView: 2,
					},
					768: {
						slidesPerView: 3,
					},
					1024: {
						slidesPerView: 5,
					},
					1280: {
						slidesPerView: 6,
					},
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className='ServicesSlider'>
				{sliders?.map((el, index) => {
					return (
						<SwiperSlide
							key={index}
							className='  py-[50px] flex justify-center bg-white  items-center'>
							<Fade
								cascade
								damping={1}
								triggerOnce
								direction={"up"}
								delay={index * 10}
								duration={800}
								className=' w-full h-full flex items-center justify-center'>
								<div className=' global_before_animation shadow-md hover:shadow-lg transition-[0.3s]  group  h-[180px] max-w-[180px] rounded-[6px] cursor-pointer   flex-col gap-[10px] flex justify-center items-center  w-full  bg-color_10'>
									<span className='!text-[50px] !font-[600] group-hover:text-color_10 text-color_03 z-10 w-10'>
										<img src={el?.image} alt='' />
									</span>
									<h5 className='text-[20px] font-[500] group-hover:font-medium group-hover:text-color_04 text-color_02 z-10 transition-[0.5s]'>
										{el.sub_title}
									</h5>
								</div>
							</Fade>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
};

export default ServicesSlider;
