import React from "react";
import GlobalButtonBox from "../GlobalButtonBox/GlobalButtonBox";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import VideoSliderBox from "../VideoSliderBox/VideoSliderBox";
import { useDispatch, useSelector } from "react-redux";
import { VideoAlbumThunk } from "../../RTK/Thunk/VideoAlbumThunk";

const VideoAlbumBox = ({ videoAlbumsTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.VideoAlbumReducer);

	React.useEffect(() => {
		dispatch(VideoAlbumThunk());
	}, []);
	return (
		<>
			<div className=' global_Padding container '>
				<GlobalTitleBox title={videoAlbumsTitle} />
				<VideoSliderBox sliders={sliders} />

				<GlobalButtonBox
					dataBt={[{ title: `عرض كافة الفيديوهات`, link: "/video-albums" }]}
				/>
			</div>
		</>
	);
};

export default VideoAlbumBox;
