// VideoPlayer.js
import { Cancel } from "@mui/icons-material";
import React from "react";

function VideoPlayer({
	videoSource,
	ShowVideo,
	setShowVideo,
	videoUrl,
	setVideoUrl,
}) {
	return (
		<div className=' block relative '>
			<div className=' fixed w-full h-full z-[1000] bg-[#0000009f]  top-0 left-0 right-0 bottom-0  '>
				<div className='container flex justify-center items-center flex-col h-full relative'>
					<div className='w-full max-w-[1024px] flex justify-end items-center'>
						<div
							onClick={() => {
								setShowVideo(false);
								setVideoUrl("");
							}}
							className=']  mb-[10px] bg-gray-200 shadow-[rgba(149,_157,_165,_0.8)_0px_8px_24px] rounded-full  cursor-pointer p-[8px]   w-[60px] h-[60px] flex justify-center items-center '>
							<Cancel className='text-global_color_bl_01 text-[30px]' />
						</div>
					</div>
					<iframe
						className=' w-full max-w-[1024px]'
						height='515'
						src={`https://www.youtube.com/embed/${videoUrl}`}
						title='YouTube video player'
						frameborder='0'
						allowfullscreen
						controls></iframe>
				</div>
			</div>
		</div>
	);
}

export default VideoPlayer;
