import { createSlice } from "@reduxjs/toolkit";
import { ContactInfoThunk } from "../Thunk/ContactInfoThunk";

let initState = {
	reload: true,
	contactInfo: {
		address: "",
		email: "",
		phone: "",
	},
	error: null,
};

const ContactInfoReducer = createSlice({
	name: "ContactInfo",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(ContactInfoThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ContactInfoThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.contactInfo = action.payload.data;
			});
	},
});
export default ContactInfoReducer.reducer;
