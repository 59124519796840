import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import { ImQuotesLeft } from "react-icons/im";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./TestimonialSlider.css";

// import required modules
import { Fade } from "react-awesome-reveal";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
let SwiperSlideData = [
	{
		title: `تبرع الآن
`,
	},
	{
		title: `طلب عضوية
`,
	},
	{
		title: `
  التقرير السنوي

`,
	},
	{
		title: `
  الفرص التطوعية
`,
	},
	{
		title: `
  تواصل معنا
`,
	},
	{
		title: `
  المبادرات
`,
	},
	{
		title: `
  التطوع
`,
	},
];

const TestimonialSlider = ({ sliders }) => {
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				dir='rtl'
				loop={true}
				pagination={{
					dynamicBullets: true,
				}}
				breakpoints={{
					640: {
						slidesPerView: 1,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
				}}
				autoplay={{
					delay: 2200,
					disableOnInteraction: false,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className='TestimonialSlider !p-[15px]'>
				{sliders?.map((el, index) => {
					return (
						<SwiperSlide
							key={index}
							className=' pb-[50px] flex justify-center items-center '>
							<Fade
								cascade
								triggerOnce
								damping={1}
								duration={800}
								direction={"up"}
								delay={index * 50}
								className=' w-full h-full flex items-center justify-center '>
								<div className=' relative cursor-pointer p-[20px]   bg-color_04 w-full  text-center  shadow-xl hover:scale-105 group transition-[0.5s]  max-w-[350px] h-[350px] min-h-[300px] max-h-[400px]  rounded-[26px]  '>
									<div className='img-box relative m-auto  w-[80px] h-[80px] group-hover:scale-110 border-[4px] group-hover:border-[5px] group-hover:border-color_03  border-color_02 rounded-full transition-[0.5s] overflow-hidden'>
										<img
											src={el?.image}
											alt=''
											className=' w-full h-full object-cover '
										/>
									</div>
									<div className='flex w-full  max-w-[310px] justify-center items-center flex-col gap-[8px]  '>
										<div className='absolute left-[2.5rem] bottom-[4.5rem]'>
											<ImQuotesLeft className='w-16 h-16 opacity-10 text-color_03' />
										</div>
										<p
											className='text-[16px] my-[20px] font-medium text-color_07 '
											dangerouslySetInnerHTML={{ __html: el.text }}
										/>

										<h5 className='text-xl font-bold text-color_02 one-line-clamp-text'>
											{el?.customer_name}
										</h5>
										<h4 className='text-[14px] font-normal text-color_03 two-line-clamp-text'>
											{el?.customer_info}
										</h4>
									</div>
								</div>
							</Fade>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
};

export default TestimonialSlider;
