import { createSlice } from "@reduxjs/toolkit";

import { SiteLogoThunk } from "../Thunk/SiteLogoThunk";

let initState = {
	reload: true,
	siteLogo: "",
	error: null,
};

const SiteLogoReducer = createSlice({
	name: "SiteLogo",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(SiteLogoThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(SiteLogoThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.siteLogo = action.payload.data?.url;
			});
	},
});
export default SiteLogoReducer.reducer;
