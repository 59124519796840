import { createSlice } from "@reduxjs/toolkit";
import { PagesThunk } from "../Thunk/PagesThunk";

let initState = {
	reload: true,
	pagesData: [],
	subPages: [],
	websiteMapPages: [],
	quickLinksPages: [],
	currentPageId: null,
	error: null,
};

const PagesReducer = createSlice({
	name: "Pages",

	initialState: initState,
	reducers: {
		setCurrentPageId: (state, action) => {
			state.subPages = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(PagesThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(PagesThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.pagesData = action.payload.data;

				state.websiteMapPages = action.payload.data?.filter(
					(page) => page?.footer === "map"
				);
				state.quickLinksPages = action.payload.data?.filter(
					(page) => page?.footer === "quick_links"
				);
			});
	},
});
export const { setCurrentPageId } = PagesReducer.actions; // Export the action creator
export default PagesReducer.reducer;
