import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { FaPlay } from "react-icons/fa";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { VideoAlbumThunk } from "../../RTK/Thunk/VideoAlbumThunk";
import LoaderPage from "./LoaderPage";
import moment from "moment/moment";
import { MainPageNamesThunk } from "../../RTK/Thunk/MainPageNamesThunk";

const VideoAlbumsPage = () => {
	const dispatch = useDispatch();

	const [ShowVideo, setShowVideo] = useState(false);
	const [videoUrl, setVideoUrl] = useState("");
	const { sliders, reload } = useSelector((state) => state.VideoAlbumReducer);
	const { videoAlbumsTitle } = useSelector(
		(state) => state.MainPageNamesReducer
	);

	React.useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch(VideoAlbumThunk());
			dispatch(MainPageNamesThunk());
		}, 0);
		return () => {
			clearTimeout(debounce);
		};
	}, []);

	if (reload) {
		return <LoaderPage />;
	}

	return (
		<>
			<div className='box  container pt-[40px] pb-[80px] '>
				<div className='box w-full h-full  pb-[30px]'>
					<GlobalTitleBox title={videoAlbumsTitle} />
				</div>
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					className=' !w-full pr-[24px]'
					spacing={3}>
					{sliders?.map((el, index) => {
						return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <Fade
                  direction={"left"}
                  triggerOnce
                  delay={index * 10}
                  cascade
                  damping={1}
                  duration={800}
                  className=" w-full  flex items-center justify-center max-w-[450px] h-[450px] max-h-[450px] min-h-[400px] "
                >
                  <div
                    onClick={() => {
                      const youtubeUrl = `${el?.link}`;
                      const parts = youtubeUrl.split("v=");

                      const videoId = parts[1];

                      setShowVideo(true);
                      setVideoUrl(videoId);
                    }}
                    className="flex w-full h-full  cursor-pointer pb-[10px] group/item bg-color_10  justify-start text-center items-center flex-col gap-[10px] shadow-md  transition-[0.3s] hover:shadow-lg  rounded-[6px] "
                  >
                    <div className="img-box w-full h-full relative max-h-[310px]">
                      <img
                        src={el.video}
                        alt=""
                        className=" rounded-b-[4px] w-full h-full object-cover"
                      />
                      <div className=" shadow-sm shadow-[#cb970d] bottom-[-35px] group-hover/item:animate-ping left-[50%] translate-x-[-50%] flex group-hover/item:w-[80px] group-hover/item:h-[80px]  group-hover/item:bg-color_02  w-[70px] h-[70px] rounded-full bg-color_03  transition-[0.3s] justify-center items-center absolute">
                        <FaPlay className="!text-[22px] font-medium text-color_06  " />
                      </div>
                    </div>
                    <div className="flex mt-[20px] w-full max-w-[310px] justify-center items-center flex-col gap-[10px]  py-[20px]  min-h-[102px]">
                      <h5 className="text-[15px]  font-[500] text-color_02 ">
                        {moment(el?.created_at).format("LL")}
                      </h5>
                      <p
                        className="text-[16px]   font-[500] text-color_08 line-clamp-text"
                        dangerouslySetInnerHTML={{ __html: el.text }}
                      ></p>
                    </div>
                  </div>
                </Fade>
              </Grid>
            );
					})}
				</Grid>
				{ShowVideo && (
					<VideoPlayer
						{...{ ShowVideo, setShowVideo, videoUrl, setVideoUrl }}
					/>
				)}
			</div>
		</>
	);
};

export default VideoAlbumsPage;
