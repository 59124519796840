import React, { useEffect, useState } from "react";
import { FaMapMarked, FaPhoneVolume } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
import "./ContactCom.css";
import { Fade } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { ContactInfoThunk } from "../../../RTK/Thunk/ContactInfoThunk";
import { ContactUsFormThunk } from "../../../RTK/Thunk/ContactUsFormThunk";
import { CircularProgress } from "@mui/material";
const ContactCom = () => {
	const dispatch = useDispatch();
	const { contactInfo } = useSelector((state) => state.ContactInfoReducer);
	const { reload } = useSelector((state) => state.ContactUsFormReducer);

	useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch(ContactInfoThunk());
		}, 0);
		return () => {
			clearTimeout(debounce);
		};
	}, []);

	const [contactUsData, setContactUsData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
	});

	const handleOnchange = (e) => {
		const { name, value } = e.target;

		setContactUsData((prevState) => {
			return { ...prevState, [name]: value };
		});
	};

	const sendMessage = () => {
		dispatch(ContactUsFormThunk(contactUsData));
	};

	useEffect(() => {
		if (reload) {
			setContactUsData({
				name: "",
				email: "",
				phone: "",
				message: "",
			});
		}
	}, [reload]);

	return (
		<div className='contact-box'>
			<div className='content border-[1px] border-color_05 bg-color_10  w-full min-h-[892px] mb-[98px] py-[26px] px-[20px] flex max-[1280px]:gap-[40px]   flex-col-reverse min-[1280px]:flex-row  justify-between items-start'>
				<section className='flex-1 max-w-[812px] flex gap-[20px] flex-col max-[1280px]:w-full max-[1280px]:mx-auto   justify-start items-start'>
					<Fade
						direction={"up"}
						triggerOnce
						delay={10}
						cascade
						damping={1}
						duration={300}
						className='flex-1 max-w-[812px] flex gap-[20px] flex-col max-[1280px]:w-full max-[1280px]:mx-auto   justify-start items-start'>
						<div>
							<h6>الإسم</h6>
							<input
								type='text'
								name='name'
								value={contactUsData?.name}
								onChange={handleOnchange}
								placeholder='قم بإدخال اسمك'
							/>
						</div>
						<div>
							<h6>البريد الإلكتروني</h6>
							<input
								type='email'
								name='email'
								value={contactUsData?.email}
								onChange={handleOnchange}
								placeholder='قم بإدخال البريد الالكتروني الخاص بك'
							/>
						</div>
						<div>
							<h6>رقم الجوال</h6>
							<input
								type='tel'
								name='phone'
								value={contactUsData?.phone}
								onChange={handleOnchange}
								style={{ direction: contactUsData?.phone ? "ltr" : "rtl" }}
								placeholder='ادخل رقم الجوال الخاص بك'
							/>
						</div>

						<div>
							<h6>تفاصيل رسالتك</h6>
							<textarea
								name='message'
								value={contactUsData?.message}
								onChange={handleOnchange}
								placeholder='قم بكتابة راسلتك بوضوح'
								className=' !h-[180px] resize-none !py-2'
							/>
						</div>

						<button
							onClick={sendMessage}
							disabled={
								!contactUsData?.name &&
								!contactUsData?.email &&
								!contactUsData?.phone &&
								!contactUsData?.message
							}
							className={` ${
								!contactUsData?.name &&
								!contactUsData?.email &&
								!contactUsData?.phone &&
								!contactUsData?.message
									? " cursor-not-allowed "
									: " cursor-pointer "
							} w-full flex items-center justify-center global_shadow rounded-[6px] transition-[0.3s] hover:bg-color_01 hover:rounded-none  font-normal text-color_06 !text-lg text-center md:h-14 h-12  px-[50px] bg-color_03 `}>
							{reload ? (
								<CircularProgress
									sx={{
										color: "#FFF",
										width: "30px !important",
										height: "30px !important",
									}}
									variant='indeterminate'
								/>
							) : (
								"ارسال"
							)}
						</button>
					</Fade>
				</section>
				<div className='info w-full min-[1280px]:max-w-[357px] max-[1280px]:max-w-[812px]  bg-color-main-06 py-[18px] px-[20px] rounded-[4px] max-[1280px]:w-full max-[1280px]:mx-auto '>
					<ul>
						<Fade
							direction={"up"}
							triggerOnce
							delay={10}
							cascade
							damping={1}
							duration={300}
							className='flex-1 max-w-[812px] flex gap-[20px] flex-col max-[1280px]:w-full max-[1280px]:mx-auto   justify-start items-start'>
							<h4 className=' mb-[14px]'>معلومات الإتصال</h4>

							<a
								href='mailto:info@celebrity.sa'
								target='blank'
								className=' flex  mb-[10px] justify-start text-[16px] font-normal text-color_08 items-center  gap-[14px]'>
								<span>
									<MdAttachEmail className=' text-[30px]  text-color_03' />
								</span>
								{contactInfo?.email}
							</a>
							<a
								href={`tel:${
									!contactInfo?.phone?.includes("+966") ||
									!contactInfo?.phone?.includes("00966")
										? `+966${contactInfo?.phone}`
										: contactInfo?.phone
								}`}
								target='blank'
								className=' flex mb-[10px] justify-start items-center text-[16px] font-normal text-color_08  gap-[14px]'>
								<span>
									<FaPhoneVolume className=' text-[30px] text-color_03' />
								</span>
								<bdi>{contactInfo?.phone}</bdi>
							</a>
							<div className=' mb-[10px] flex justify-start text-[16px] font-normal text-color_08 items-center  gap-[14px]'>
								<span>
									<FaMapMarked className=' text-[30px] text-color_03' />
								</span>
								{contactInfo?.address}
							</div>
						</Fade>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default ContactCom;
