
import React from 'react';
import GlobalTitleBox from '../../Components/GlobalTitleBox/GlobalTitleBox';
import ContactCom from '../../Components/Pages/ContactCom/ContactCom';
const ContactUsPage = () => {
  return (
    <>
      <div className="box   container pt-[40px] pb-[80px] ">
        <div className="box w-full h-full  pb-[30px]">
          <GlobalTitleBox title={"اتصل بنا"} />
        </div>
        {/* <ContactForm /> */}
        <ContactCom />
      </div>
    </>
  );
}

export default ContactUsPage;


