import { Grid } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { ImQuotesLeft } from "react-icons/im";
import GlobalTitleBox from "../../Components/GlobalTitleBox/GlobalTitleBox";
import { useDispatch, useSelector } from "react-redux";
import { TestimonialsThunk } from "../../RTK/Thunk/TestimonialsThunk";
import LoaderPage from "./LoaderPage";
import { MainPageNamesThunk } from "../../RTK/Thunk/MainPageNamesThunk";

const TestimonialPage = () => {
	const dispatch = useDispatch();
	const { sliders, reload } = useSelector((state) => state.TestimonialsReducer);

	const { testimonialsTitle } = useSelector(
		(state) => state.MainPageNamesReducer
	);

	React.useEffect(() => {
		const debounce = setTimeout(() => {
			dispatch(TestimonialsThunk());
			dispatch(MainPageNamesThunk());
		}, 0);
		return () => {
			clearTimeout(debounce);
		};
	}, []);

	if (reload) {
		return <LoaderPage />;
	}

	return (
		<>
			<div className='box  container pt-[40px] pb-[80px] '>
				<div className='box w-full h-full  pb-[30px]'>
					<GlobalTitleBox title={testimonialsTitle} />
				</div>
				<Grid
					container
					justifyContent='center'
					alignItems='center'
					className=' !w-full pr-[24px]'
					spacing={3}>
					{sliders?.map((el, index) => {
						return (
							<Grid key={index} item xs={12} sm={6} md={4} xl={3}>
								<Fade
									direction={"up"}
									triggerOnce
									delay={index * 50}
									cascade
									damping={1}
									duration={800}
									className=' w-full h-full flex items-center justify-center'>
									<div className=' relative cursor-pointer p-[20px]   bg-color_04 w-full  text-center  shadow-xl hover:scale-105 group transition-[0.5s]  max-w-[350px] h-[350px] min-h-[300px] max-h-[400px]  rounded-[26px]  '>
										<div className='img-box relative m-auto  w-[80px] h-[80px] group-hover:scale-110 border-[4px] group-hover:border-[5px] group-hover:border-color_03  border-color_02 rounded-full transition-[0.5s] overflow-hidden'>
											<img
												src={el?.image}
												alt=''
												className=' w-full h-full object-cover '
											/>
										</div>
										<div className='flex w-full  max-w-[310px] justify-center items-center flex-col gap-[8px]  '>
											<div className='absolute left-[2.5rem] bottom-[4.5rem]'>
												<ImQuotesLeft className='w-16 h-16 opacity-10 text-color_03' />
											</div>
											<p
												className='text-[16px] my-[20px] font-medium text-color_07 '
												dangerouslySetInnerHTML={{ __html: el.text }}
											/>

											<h5 className='text-xl font-bold text-color_02 one-line-clamp-text'>
												{el?.customer_name}
											</h5>
											<h4 className='text-[14px] font-normal text-color_03 two-line-clamp-text'>
												{el?.customer_info}
											</h4>
										</div>
									</div>
								</Fade>
							</Grid>
						);
					})}
				</Grid>
			</div>
		</>
	);
};

export default TestimonialPage;
