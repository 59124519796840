import Box from "@mui/material/Box";

import { NavLink, Outlet } from "react-router-dom";
import AppBarBox from "../../Components/AppBar/AppBar";
import Footer from "../../Components/Footer/Footer";
import GoTopBox from "../../Components/GoTopBox/GoTopBox";
import WhatsAppBox from "../../Components/WhatsAppBox/WhatsAppBox";
import { LuLayoutPanelTop } from "react-icons/lu";

// Icons
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { PagesThunk } from "../../RTK/Thunk/PagesThunk";
import {
  Md6FtApart,
  MdConnectWithoutContact,
  MdGeneratingTokens,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";
import { FaPhotoFilm } from "react-icons/fa6";
import { FaHome, FaNewspaper, FaVideo } from "react-icons/fa";

import { BiSolidMessageAltDetail } from "react-icons/bi";
import DropDownBox from "../../Common/DropDownBox/DropDownBox";
import DropDownBoxHeader from "../../Common/DropDownBoxInHeader/DropDownBoxInHeader";
import { useRef } from "react";
import { Newspaper } from "@mui/icons-material";
import { LastNewsThunk } from "../../RTK/Thunk/LastNewsThunk";
const navItemsLinkStart = [
	{
		title: "الرئسية",
		link: "/",
		icon: <FaHome />,

		id: "",
	},


];
const navItemsLinkEnd = [

  {
    title: "تواصل معنا",
    link: "/contact-us",
    icon: <MdConnectWithoutContact />,
    id: "",
  },
];

const Layout = (props) => {
	const dispatch = useDispatch();
	const { pagesData } = useSelector((state) => state.PagesReducer);
	  const { sliders } = useSelector((state) => state.LastNewsReducer);

const getDataRef = useRef(true);
React.useEffect(() => {
	    // console.log("getDataRef.current",getDataRef.current);
  if (getDataRef.current) {
    getDataRef.current = false;
         dispatch(PagesThunk());
             dispatch(LastNewsThunk());

  }
}, []);

	// React.useEffect(() => {
  //   const debounce = setTimeout(() => {
  //     dispatch(PagesThunk());
  //   }, 0);
  //   return () => {
  //     clearTimeout(debounce);
  //   };
  // }, [dispatch]);

	return (
    <>
      <AppBarBox />

      <Box
        sx={{ display: { xs: "none", lg: "block" } }}
        className=" global_shadow sticky z-[1010] top-0 bg-color_02 py-3 w-full   "
      >
        <div className="container  flex flex-wrap justify-center gap-[10px] items-center">
          {navItemsLinkStart.map((item) => (
            <NavLink
              key={item}
              to={item?.link}
              className="nav_link_max flex gap-[5px] justify-center items-center transition-[0.3s] text-color_06 text-[17px] font-medium px-4 py-1 rounded"
            >
              <span>{item?.icon}</span>
              {item?.title}
            </NavLink>
          ))}

          {pagesData?.map((page) => (
            <DropDownBoxHeader
              id={page?.id}
              StatusItem={page?.status}
              HasSection={page?.has_sections}
              title={page?.name}
              icon={page?.icon}
              items={page?.subPages}
            />
          ))}
          {sliders?.length !== 0 && (
            <NavLink
              to="/news"
              className="nav_link_max flex gap-[5px] justify-center items-center transition-[0.3s] text-color_06 text-[17px] font-medium px-4 py-1 rounded"
            >
              <span>
                <Newspaper />
              </span>
              الأخبار
            </NavLink>
          )}

          {navItemsLinkEnd.map((item) => (
            <NavLink
              key={item}
              to={item?.link}
              className="nav_link_max flex gap-[5px] justify-center items-center transition-[0.3s] text-color_06 text-[17px] font-medium px-4 py-1 rounded"
            >
              <span>{item?.icon}</span>
              {item?.title}
            </NavLink>
          ))}
        </div>
      </Box>
      {/* <div className=" relative w-full pt-[98px] sm:pt-[115px] "> */}
      <Outlet />
      <Footer />
      <GoTopBox />
      <WhatsAppBox />
      {/* </div> */}
    </>
  );
};

export default Layout;
