import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./HeroSliderBox.css";

// import required modules
import { Autoplay, Pagination } from "swiper/modules";
import { useDispatch, useSelector } from "react-redux";
import { MainSectionThunk } from "../../RTK/Thunk/MainSectionThunk";

const HeroSliderBox = () => {
	const dispatch = useDispatch();
	const { heroSliders } = useSelector((state) => state.MainSectionReducer);

	React.useEffect(() => {
		dispatch(MainSectionThunk());
	}, []);

	return (
		<>
			<Swiper
				centeredSlides={true}
				pagination={{
					dynamicBullets: true,
				}}
				loop={true}
				autoplay={{
					delay: 4500,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Pagination]}
				className='HeroSliderBox'>
				{heroSliders?.map((slider) =>
					slider?.link ? (
						<SwiperSlide
							key={slider?.id}
							className=' cursor-pointer'
							onClick={() => window.open(slider?.link, "_blank")}>
							<img src={slider?.image} alt='' />
						</SwiperSlide>
					) : (
						<SwiperSlide key={slider?.id}>
							<img src={slider?.image} alt='' />
						</SwiperSlide>
					)
				)}
			</Swiper>
		</>
	);
};

export default HeroSliderBox;
