import React, { useRef } from "react";
import HeroSliderBox from "../../Components/HeroSliderBox/HeroSliderBox";
import NewSBox from "../../Components/NewSBox/NewSBox";
import OurAchievementsBox from "../../Components/OurAchievementsBox/OurAchievementsBox";
import PartnersBox from "../../Components/PartnersBox/PartnersBox";
import PhotoAlbumBox from "../../Components/PhotoAlbumBox/PhotoAlbumBox";
import ServicesBox from "../../Components/ServicesBox/SerivesBox";
import VideoAlbumBox from "../../Components/VideoAlbumBox/VideoAlbumBox";
import TestimonialBox from "../../Components/TestimonialBox/TestimonialBox";
import { useDispatch, useSelector } from "react-redux";
import { MainPageSectionsStatusThunk } from "../../RTK/Thunk/MainPageSectionsStatusThunk";
import AboutUsSection from "../../Components/AboutUsSection/AboutUsSection";
import LoaderPage from "./LoaderPage";
import { MainPageNamesThunk } from "../../RTK/Thunk/MainPageNamesThunk";

const HomePage = () => {
	const dispatch = useDispatch();

	const {
		main_sections,
		who_are_we,
		electronic_services,
		results,
		news,
		images,
		videos,
		testimonials,
		partners,
		reload,
	} = useSelector((state) => state.MainPageSectionsStatusReducer);

	const {
		electronicServicesTitle,
		achievementsTitle,
		newsTitle,
		photoAlbumsTitle,
		videoAlbumsTitle,
		testimonialsTitle,
		partnersTitle,
	} = useSelector((state) => state.MainPageNamesReducer);
const getDataRef=React.useRef(true)
	React.useEffect(() => {
    if (getDataRef.current) {
      getDataRef.current = false;
      dispatch(MainPageSectionsStatusThunk());
      dispatch(MainPageNamesThunk());
    }
  }, [getDataRef]);

	if (reload) {
		return <LoaderPage />;
	}

	return (
		<>
			{main_sections ? <HeroSliderBox /> : null}
			{who_are_we ? <AboutUsSection /> : null}
			{electronic_services ? (
				<ServicesBox electronicServicesTitle={electronicServicesTitle} />
			) : null}

			{results ? (
				<OurAchievementsBox achievementsTitle={achievementsTitle} />
			) : null}
			{news ? <NewSBox newsTitle={newsTitle} /> : null}
			{images ? <PhotoAlbumBox photoAlbumsTitle={photoAlbumsTitle} /> : null}
			{videos ? <VideoAlbumBox videoAlbumsTitle={videoAlbumsTitle} /> : null}
			{testimonials ? (
				<TestimonialBox testimonialsTitle={testimonialsTitle} />
			) : null}
			{partners ? <PartnersBox partnersTitle={partnersTitle} /> : null}
		</>
	);
};

export default HomePage;
