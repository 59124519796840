import { createSlice } from "@reduxjs/toolkit";

import { AboutUsSectionThunk } from "../Thunk/AboutUsSectionThunk";

let initState = {
	reload: true,
	data: {},

	error: null,
};

const AboutUsSectionReducer = createSlice({
	name: "AboutUsSection",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(AboutUsSectionThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(AboutUsSectionThunk.fulfilled, (state, action) => {
				state.reload = false;

				state.data = action.payload.data;
			});
	},
});
export default AboutUsSectionReducer.reducer;
