import { createSlice } from "@reduxjs/toolkit";

import { ElectronicServicesThunk } from "../Thunk/ElectronicServicesThunk";

let initState = {
	reload: true,
	sliders: [],

	error: null,
};

const ElectronicServicesReducer = createSlice({
	name: "ElectronicServices",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(ElectronicServicesThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(ElectronicServicesThunk.fulfilled, (state, action) => {
				state.reload = false;

				state.sliders = action.payload.data;
			});
	},
});
export default ElectronicServicesReducer.reducer;
