import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./PartnersSlide.css";

// import required modules
import { Fade } from "react-awesome-reveal";
import { Autoplay, Pagination } from "swiper/modules";

const PartnersSlider = ({ sliders }) => {
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				dir='rtl'
				loop={true}
				pagination={{
					dynamicBullets: true,
				}}
				breakpoints={{
					640: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 4,
						spaceBetween: 40,
					},
					1024: {
						slidesPerView: 5,
						spaceBetween: 50,
					},
				}}
				autoplay={{
					delay: 2500,
					disableOnInteraction: false,
				}}
				modules={[Autoplay, Pagination]}
				className='PartnersSlider'>
				{sliders?.map((el, index) => {
					return (
						<SwiperSlide
							key={index}
							className='  py-[50px] flex justify-center bg-white  items-center'>
							<Fade
								direction={"down"}
								triggerOnce
								delay={index * 100}
								cascade
								damping={1}
								duration={800}
								className=' w-full h-full flex items-center justify-center'>
								<div className='    flex-col gap-[10px] flex justify-center items-center  w-full  '>
									<div className='img-box w-full max-sm:w-[60%] h-[130px] relative   rounded-full overflow-hidden'>
										<img
											src={el.image}
											alt=''
											className=' w-full h-full object-contain'
										/>
									</div>
									<h5 className='text-[16px] max-sm:w-[80%] font-[600] text-color_02 line-clamp-text'>
										{el.sub_title}
									</h5>
								</div>
							</Fade>
						</SwiperSlide>
					);
				})}
			</Swiper>
		</>
	);
};

export default PartnersSlider;
