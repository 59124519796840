import { createSlice } from "@reduxjs/toolkit";

import { TestimonialsThunk } from "../Thunk/TestimonialsThunk";

let initState = {
	reload: true,
	sliders: [],
	error: null,
};

const TestimonialsReducer = createSlice({
	name: "VideoAlbumReducer",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(TestimonialsThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(TestimonialsThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.sliders = action.payload.data;
			});
	},
});
export default TestimonialsReducer.reducer;
