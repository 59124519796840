import { createSlice } from "@reduxjs/toolkit";

import { SocialLinksThunk } from "../Thunk/SocialLinksThunk";

let initState = {
	reload: true,
	socialLinks: [],
	error: null,
};

const SocialLinksReducer = createSlice({
	name: "socialLinks",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(SocialLinksThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(SocialLinksThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.socialLinks = action.payload.data;
			});
	},
});
export default SocialLinksReducer.reducer;
