import React from "react";
import GlobalButtonBox from "../GlobalButtonBox/GlobalButtonBox";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import PartnersSlider from "../PartnersSlider/PartnersSlider";
import { useDispatch, useSelector } from "react-redux";
import { PartnersThunk } from "../../RTK/Thunk/PartnersThunk";

const PartnersBox = ({ partnersTitle }) => {
	const dispatch = useDispatch();
	const { sliders } = useSelector((state) => state.PartnersReducer);

	React.useEffect(() => {
		dispatch(PartnersThunk());
	}, []);
	return (
		<>
			<div className='flex justify-start py-[60px] items-center flex-col  w-full min-h-[400px] '>
				<GlobalTitleBox title={partnersTitle} />
				<div className='container'>
					<PartnersSlider sliders={sliders} />

					<GlobalButtonBox
						dataBt={[{ title: `عرض الكل `, link: "/partners-page" }]}
					/>
				</div>
			</div>
		</>
	);
};

export default PartnersBox;
