import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./PhotoAlbumSliderBox.css";

// import required modules
import { Fade } from "react-awesome-reveal";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import moment from "moment/moment";

const PhotoAlbumSliderBox = ({ sliders }) => {
	return (
		<>
			<Swiper
				slidesPerView={1}
				spaceBetween={10}
				dir='rtl'
				loop={true}
				pagination={{
					dynamicBullets: true,
				}}
				breakpoints={{
					640: {
						slidesPerView: 1,
						spaceBetween: 20,
					},
					768: {
						slidesPerView: 2,
						spaceBetween: 20,
					},
					1024: {
						slidesPerView: 3,
						spaceBetween: 30,
					},
				}}
				autoplay={{
					delay: 2600,
					disableOnInteraction: false,
				}}
				navigation={true}
				modules={[Autoplay, Pagination, Navigation]}
				className='PhotoAlbumSliderBox !p-[15px]'>
				{sliders?.map((el, index) => {

					return (
            <SwiperSlide
              key={index}
              className="pb-[50px] flex justify-center   items-center "
            >
              <Fade
                direction={"up"}
                triggerOnce
                delay={index * 100}
                cascade
                damping={1}
                duration={800}
                className=" w-full h-full flex items-center justify-center"
              >
                <div className="flex cursor-pointer  p-[20px]  bg-color_10    justify-start text-center items-center flex-col gap-[10px] transition-[0.3s]  max-w-[350px] h-[350px] max-h-[380px] min-h-[350px] rounded-md photo_album_slider_bg_animation shadow-md hover:shadow-lg">
                  <div className="img-box w-full h-full relative  z-10  rounded-[6px] overflow-hidden">
                    <img
                      src={el?.image}
                      alt=""
                      className=" w-full h-full object-cover "
                    />
                  </div>
                  <div className="flex w-full max-w-[310px] justify-center items-center flex-col gap-[8px] z-10 min-h-[102px]">
                    <h5 className="text-[15px]  border-color_06 font-[500] text-color_01 ">
                      {moment(el?.created_at).format("LL")}
                    </h5>
                    <h4 className="text-[16px]  border-color_06 font-[500] text-color_01 line-clamp-text ">
                      <p dangerouslySetInnerHTML={{ __html: el.text }}></p>
                    </h4>
                  </div>
                </div>
              </Fade>
            </SwiperSlide>
          );
				})}
			</Swiper>
		</>
	);
};

export default PhotoAlbumSliderBox;
