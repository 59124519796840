import { createSlice } from "@reduxjs/toolkit";

import { MainPageNamesThunk } from "../Thunk/MainPageNamesThunk";

let initState = {
	reload: true,

	partnersTitle: null,
	mainSectionsTitle: null,
	achievementsTitle: null,
	newsTitle: null,
	photoAlbumsTitle: null,
	videoAlbumsTitle: null,
	whoAreWeTitle: null,
	electronicServicesTitle: null,
	testimonialsTitle: null,

	error: null,
};

const MainPageNamesReducer = createSlice({
	name: "MainPageNames",

	initialState: initState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(MainPageNamesThunk.pending, (state, action) => {
				state.reload = true;
			})
			.addCase(MainPageNamesThunk.fulfilled, (state, action) => {
				state.reload = false;
				state.partnersTitle = action.payload.data.partners;
				state.mainSectionsTitle = action.payload.data.main_sections;
				state.achievementsTitle = action.payload.data.achievements;
				state.newsTitle = action.payload.data.news;
				state.photoAlbumsTitle = action.payload.data.photo_albums;
				state.videoAlbumsTitle = action.payload.data.video_albums;
				state.whoAreWeTitle = action.payload.data.who_are_we;
				state.electronicServicesTitle = action.payload.data.electronic_services;
				state.testimonialsTitle = action.payload.data.testimonials;
			});
	},
});
export default MainPageNamesReducer.reducer;
